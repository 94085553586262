exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-default-jsx": () => import("./../../../src/templates/page/default.jsx" /* webpackChunkName: "component---src-templates-page-default-jsx" */),
  "component---src-templates-page-editorial-jsx": () => import("./../../../src/templates/page/editorial.jsx" /* webpackChunkName: "component---src-templates-page-editorial-jsx" */),
  "component---src-templates-page-listing-funds-jsx": () => import("./../../../src/templates/page/listing-funds.jsx" /* webpackChunkName: "component---src-templates-page-listing-funds-jsx" */),
  "component---src-templates-template-ol-fund-jsx": () => import("./../../../src/templates/template-ol-fund.jsx" /* webpackChunkName: "component---src-templates-template-ol-fund-jsx" */),
  "component---src-templates-template-ol-fund-resultatscenarier-jsx": () => import("./../../../src/templates/template-ol-fund-resultatscenarier.jsx" /* webpackChunkName: "component---src-templates-template-ol-fund-resultatscenarier-jsx" */),
  "component---src-templates-template-person-jsx": () => import("./../../../src/templates/template-person.jsx" /* webpackChunkName: "component---src-templates-template-person-jsx" */)
}

